.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.detail-caption {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.detail-arrow {
  display: inline-block;
  width: 48%;
  margin: 1%;
  text-align: center;
}

.detail-arrow-container {
  max-width: 75%;
  text-align: center;
  margin: 0 auto;
}

.detail-button {
  border: 2px solid black;
  text-align: center;
}

.detail-button:hover {
  background-color: white;
}

.detail-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-width: 85%;
  max-height: 750px;
  height: auto;
  border: 10px solid black;
}

.detail-info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* opacity: 1; */
  animation: tooltip-fade 5s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

@keyframes tooltip-fade {
  0% {
    visibility: visible;
    opacity: 1;
  } 
  65% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contact-name {
  font-size: 250%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.contact-bio {
  font-size: 125%;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  hyphens: auto;
}

.construction {
  font-size: 300%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

/* .menubar {
  margin-bottom: 150px;
} */

.bio-img {
  max-width: 50%;
  height: auto;
  display: block;
  margin-left: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.home-img {
  max-width: 75%;
  height: auto;
  image-orientation: from-image;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  border: 10px solid black;
}

.home-div {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.detail-col {
  margin-top: 50px;
}

.website-head {
  margin: 20px;
  font-family: 'Great Vibes', cursive;
  font-size: 300%;
  color: #000000;
}

.contact-form {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.ant-row, .ant-form-item {
  margin: 0 0 0 0;
}

.submit-error{
  color: red;
  display: inline;
  margin-left: 10px;
}

.gallery {
  text-align: center;
}

.gallery-row {
  display: inline-block;
  margin: 0 auto;
  
}

.gallery-img {
  display: inline-block;
  margin: 10px;
  border: 5px solid black;
}