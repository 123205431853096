/* @import '~antd/dist/antd.css'; */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #9fb6ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menubar {
  margin-bottom: 50px;
  font-size: 18pt;
  text-align: center;
  vertical-align: center;
}

.menutext{
  margin-top: 5%;
  /* margin-bottom: 10%; */
  color: #000000;
  text-decoration: underline;
}

.menutext:hover {
  color:#0000EE;
  text-decoration: underline;
}

.component {
  margin: 10px;
}